/*-----------------------
    Team Member Area
------------------------*/
.team-member-area{
    position: relative;
    z-index: 0;
    padding-top: 112px;
    overflow: hidden;
    .bg-shape-1{
        position: absolute;
        left: 0;
        top: 50px;
        width: 100%;
        height: 100%;
    }
    .bg-shape-2{
        position: absolute;
        left: 0;
        bottom: 0px;
    }
    .bg-shape-3{
        position: absolute;
        right: 0;
        bottom: 50px;
    }
}
.single-team-member{
    text-align: center;
    display: inline-block;
    &.white{
        .content{
            .title{
                color: $white;
            }
            .post{
                color: rgba($white,.7);
            }
        }
    }
    &:hover{
        .thumb{
            .hover{
                visibility: visible;
                opacity: 1;
                @include transform(scale(1));
            }
        }
    }
    .thumb{
        display: inline-block;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 34px 95px 0px rgba(22, 12, 43, 0.18);
        border-radius: 50%;
        border: 8px solid $white;
        position: relative;
        z-index: 0;
        margin-bottom: 27px;
      
        img{
            width: 200px;
            height: 200px;
            border-radius: 50%;
        }
        .hover{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($base-color,.7);
            border-radius: 50%;
            visibility: hidden;
            opacity: 0;
            @include transform(scale(0));
            @include transition($transition);
            .social-icon{
                margin: 0;
                padding: 0;
                list-style: none;
                position: absolute;
                left: 50%;
                bottom: 30px;
                @include transform(translateX(-50%));
                li{
                    display: inline-block;
                    margin: 0 5px;
                    a{
                        display: block;
                        color: $white;
                        i{
                            @include transition($transition);
                        }
                        &:hover{
                            i{
                                @include transform(scale(1.2));
                            }
                        }
                    }
                }
            }
        }
    }
    .content{
        .title{
            font-size: 22px;
            line-height: 28px;
            color: #283659;
            margin-bottom: 5px;
        }
        .post{
            font-size: 14px;
            line-height: 24px;
        }
    }
}
.download-area-wrapper{
    background-color: $white;
    box-shadow: 0px 0px 169px 0px rgba(0, 0, 0, 0.08);
    text-align: center;
    padding: 93px 100px 100px 100px;
    &.white{
        background-color: #131333;
        .title{
            color: $white;
        }
        .subtitle{
            color: rgba($white,.8);
        }
        p{
            color: rgba($white,.7);
        }
    }
    p{
        max-width: 750px;
        margin: 0 auto;
    }
    .title {
        font-size: 48px;
        line-height: 58px;
        margin-bottom: 10px;
        font-weight: 700;
        color: $heading-color;
    }
    .subtitle{
        font-size: 20px;
        line-height: 30px;
        color: $base-color;
        text-transform: uppercase;
        font-weight: 600;
        font-family: $heading-font;
        display: block;
        margin-bottom: 10px;
    }
    .btn-wrapper{
        margin-top: 57px;
        .boxed-btn{
            margin: 0 10px;
        }
    }
}

.contact-area-wrapper{
    padding: 113px 0 120px 0;
    &.white{
        .title{
            color: $white;
        }
        .subtitle{
            color: rgba($white,.8);
        }
        p{
            color: rgba($white,.7);
        }
    }
    .title {
        // font-size: 48px;
        // line-height: 58px; 
        margin-bottom: 20px;
        // font-weight: 700;
        // color: $heading-color;
        font-weight: 600;
        font-size: 45px;
        line-height: 1.2; 
        color: #454356; 
    }
    .subtitle{
        // font-size: 20px;
        // line-height: 30px;
        // color: $base-color;
        text-transform: uppercase;
        // font-weight: 600;
        font-family: $heading-font;
        display: block;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 35px;
        line-height: 1.2; 
        color: #454356; 
    }
    .headline {
        font-size: 22px;
        line-height: 1.3;
        color: #454356;
        max-width: none;
        font-weight: 200;
    }
}
.contact-form{
    &.sec-margin{
        margin-top: 40px; 
    }
    .form-group{
        &.textarea{
            .form-control{
                resize: none;
                padding: 20px;
                min-height: 150px;
                &:focus{
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        .form-control{
            border: 1px solid #ededed;
            height: 60px;
            padding: 0 20px;
            border-radius: 3px;
            border-color: #9390D3;
            font-weight: 200;
            font-size: 20px;
            &.error {
                border-color: red !important; 
            }
        }
    }
}