/*----------------------------
    Testimonial Area
-----------------------------*/
.testimonial-area{
    padding-bottom: 120px;
    position: relative;
    .shape-1{
        position: absolute;
        right: 10%;
        bottom: 40%;
        animation: upndown 10s linear 2s infinite;
    }
    .shape-2{
        position: absolute;
        right: 6%;
        bottom: 30%;
        animation: upndown 8s linear 2s infinite;
        opacity: .5;
    }
}
.single-testimonial-item{
    position: relative;
    z-index: 0;
    img{
        width: auto !important;
    }
    .hover{
        position: absolute;
        right: 0;
        top: 50%;
        width: 50%;
        background-color: $white;
      
        box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.08);
        margin-right: 30px;
        @include transform(translateY(-50%));
        .hover-inner{
            text-align: center;
            padding: 50px 50px 43px 50px;
            .icon{
                position: absolute;
                top: -40px;
                left: 50%;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-image: -moz-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
                background-image: -webkit-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
                background-image: -ms-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
                line-height: 80px;
                color: $white;
                @include transform(translateX(-50%));
            }
           p{
                font-size: 24px;
                line-height: 34px;
                margin-top: 15px;
           }
           .author-meta{
               margin-top: 33px;
               .name{
                   font-size: 18px;
                   text-transform: uppercase;
               }
               .post{
                   font-size: 14px;
               }
           }
        }
    }
}


.single-testimonial-item{
    .thumb{
        position: relative;
        display: block;
        mask-repeat: no-repeat;
        mask-image: url(../img/mask-image.png);
        mask-size: 100% 100%;
        overflow: hidden;
        width: 763px;
        height: 827px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}

@media only screen and (max-width:768px){
    .single-testimonial-item .thumb {
        width: 100%;
        height: 630px;
    }
}

@media only screen and (max-width:500px){
    .single-testimonial-item .thumb {
        width: 100%;
        height: 530px;
    }
}
.single-testimonial-item-03{
    position: relative;
    z-index: 0;
    margin-bottom: 40px;
    .img-wrapper{
        position: absolute;
        left:30px;
        bottom: -40px;
        img{
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }
    }
    .content-area{
        background-image: -moz-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
        background-image: -webkit-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
        background-image: -ms-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
        padding: 55px 30px 65px 30px;
        position: relative;
        z-index: 0;
        overflow: hidden;
        border-radius: 10px;
        &:after{
            position: absolute;
            right: 30px;
            top:-20px;
            font-size: 80px;
            line-height: 80px;
            content: "\f10e";
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            color: rgba($white,.2)
        }
        p{
            font-size: 18px;
            line-height: 30px;
            color: rgba($white,.9);
        }
        .author-meta{
            margin-top: 20px;
            .title{
                font-size: 20px;
                font-weight: 600;
                line-height: 32px;
                color: $white;
                margin-bottom: 0px;
            }
            .designation{
                font-size: 16px;
                line-height: 26px;
                color: rgba($white,.9);
                font-weight: 400;
            }
        }
    }
}
.single-testimonial-item-02{
    background-image: -moz-linear-gradient( 176deg, rgb(2,80,197) 0%, rgb(212,63,141) 100%);
    background-image: -webkit-linear-gradient( 176deg, rgb(2,80,197) 0%, rgb(212,63,141) 100%);
    background-image: -ms-linear-gradient( 176deg, rgb(2,80,197) 0%, rgb(212,63,141) 100%);
    border-radius: 5px;
    display: flex;
    align-self: flex-start;
    .img-wrapper{
        width: calc(100% - 60%);
        .bg-image{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background-size: cover;
            background-position: center;
        }
    }
    .content-area{
        width: calc(100% - 40%);
        flex: 1;
        padding: 70px 60px 72px 60px;
        position: relative;
        z-index: 0;
        overflow: hidden;
        &:after{
            position: absolute;
            right: 30px;
            bottom:-10px;
            font-size: 80px;
            line-height: 80px;
            content: "\f10d";
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            color: rgba($white,.2)
        }
        p{
            font-size: 20px;
            line-height: 40px;
            color: rgba($white,.9);
        }
        .author-meta{
            margin-top: 25px;
            .title{
                font-size: 26px;
                font-weight: 700;
                line-height: 40px;
                color: $white;
                margin-bottom: 0px;
            }
            .designation{
                font-size: 16px;
                line-height: 26px;
                color: rgba($white,.9);
            }
        }
    }
}

.testimonial-area-three{
    &.mirgin-minus{
        padding-top: 362px;
    }
    &.bg-image{
        background-image: url(../img/bg/testimonial-bg-03.jpg);
        background-position: center;
        background-size: cover;
        position: relative;
        z-index: 0;
        &:after{
            position: absolute;
            left:0px;
            top: 0px;
            width: 100%;
            height: 100%;
            content: '';
            background-color: rgba(#000,.5);
            z-index: -1;
        }
    }
}