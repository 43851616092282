/*---------------------------
** Global 
---------------------------*/
:root{
    --main-color-one: #500ade;
    --main-color-two: #852aff;
    --secondary-color: #333333;
    --heading-color: #1c144e;
    --paragraph-color: #666666;
    --heading-font: 'Poppins', sans-serif;
    --body-font: 'Poppins', sans-serif;
}
.dark-bg{
    background-color: #10102d;
}
.remove-col-padding {
    padding: 0;
}

.remove-col-padding-right {
    padding-right: 0;
}

.remove-col-padding-left {
    padding-left: 0;
}

.padding-left-0 {
    padding-left: 0;
}

.padding-right-0 {
    padding-left: 0;
}
.padding-bottom-120{
    padding-bottom: 120px;
}
//== padding classes 
.padding-top-10 {
    padding-top: 10px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-30 {
    padding-top: 30px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-70 {
    padding-top: 70px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-90 {
    padding-top: 90px;
}

.padding-top-100 {
    padding-top: 100px;
}
.padding-top-110 {
    padding-top: 110px;
}
.padding-top-120 {
    padding-top: 120px;
}

//== margin classes 
.margin-top-10 {
    margin-top: 10px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-70 {
    margin-top: 70px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-90 {
    margin-top: 90px;
}

.margin-top-100 {
    margin-top: 100px;
}

//= margin bottom
.margin-bottom-0 {
    margin-bottom: 0px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-70 {
    margin-bottom: 70px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-90 {
    margin-bottom: 90px;
}

.margin-top-100 {
    margin-bottom: 100px;
}

.margin-top-120 {
    margin-top: 120px;
}
.margin-top-minus-290{
    margin-top: -290px;
}
.padding-left-0 {
    padding-left: 0px !important;
}

.section-subtitle {
    font-size: 18px;
    line-height: 28px;
    text-transform: uppercase;
    color: $base-color;
    font-weight: 700;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .4rem;
    padding-left: 0.4rem;
}

.back-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 50px;
    height: 50px;
    background-color: $base-color;
    color: $white;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    z-index: 99;
    cursor: pointer;
    font-size: 30px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.10);
}
.gray-bg{
    background-color: #f7f7f7;
}
.video-play-btn {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 16px;
    background-color: $white;
    border-radius: 50%;
    color: #313131;
    &:before {
        content: "";
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        @include transform(translateX(-50%) translateY(-50%));
        display: block;
        width: 80px;
        height: 80px;
        background: $white;
        border-radius: 50%;
        animation: pulse-border 1500ms ease-out infinite;
        z-index: -1;
    }
    &:hover {
        color: #313131;
    }
}
@include keyframes(pulse-border) {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
        opacity: 0;
    }
}
@media only screen and (max-width: 991px) {
    .row.reorder-xs {
        @include transform(rotate(180deg));
        direction: rtl;
    }
    .row.reorder-xs>[class*="col-"] {
        @include transform(rotate(-180deg));
        direction: ltr;
    }
}
.bg-blue{
    background-color: #1c144e;
}
.padding-60{
    padding:60px 0 60px 0;
}
.submit-btn-02{
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 400;  
    color: $white;
    width: 200px;
    background-color: #9390D3;
    padding: 0 20px;
    @include transition($transition);
    cursor: pointer;
    border: none;
    border-radius: 5px;
    &:hover {
        color: $white;
        background-color: #7b78b9;
    }
}
.back-to-home {
    color: #7b78b9;
    text-decoration: none;
    margin-bottom: 100px;
}
.boxed-btn {
    display: inline-block;
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    color: $white;
    width: 202px;
    background-color: $base-color;
    padding: 0 20px;
    @include transition($transition);
    &:hover {
        color: $white;
        background-color: $secondary-color;
    }
    &.gd-bg-1{
        background-image: -moz-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
        background-image: -webkit-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
        background-image: -ms-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
        &:hover{
            background-image: -moz-linear-gradient( 50deg, rgb(151,73,248) 0%,  rgb(94,44,237)100%);
            background-image: -webkit-linear-gradient( 50deg,rgb(151,73,248)  0%, rgb(94,44,237) 100%);
            background-image: -ms-linear-gradient( 50deg, rgb(151,73,248) 0%, rgb(94,44,237) 100%);
        }
    }
    &.gd-bg-2{
        background-image: -moz-linear-gradient( 50deg, rgb(232,119,203) 0%, rgb(161,151,250) 100%);
        background-image: -webkit-linear-gradient( 50deg, rgb(232,119,203) 0%, rgb(161,151,250) 100%);
        background-image: -ms-linear-gradient( 50deg, rgb(232,119,203) 0%, rgb(161,151,250) 100%);
        &:hover{
            background-image: -moz-linear-gradient( 50deg, rgb(161,151,250) 0%,  rgb(232,119,203) 100%);
            background-image: -webkit-linear-gradient( 50deg, rgb(161,151,250) 0%, rgb(232,119,203) 100%);
            background-image: -ms-linear-gradient( 50deg, rgb(161,151,250) 0%, rgb(232,119,203) 100%);
        }
    }
    &.gd-bg-3{
        background-image: -moz-linear-gradient( 50deg, rgb(112,191,236) 0%, rgb(39,132,252) 100%);
        background-image: -webkit-linear-gradient( 50deg, rgb(112,191,236) 0%, rgb(39,132,252) 100%);
        background-image: -ms-linear-gradient( 50deg, rgb(112,191,236) 0%, rgb(39,132,252) 100%);
        &:hover{
            background-image: -moz-linear-gradient( 50deg, rgb(39,132,252) 0%, rgb(112,191,236) 100%);
            background-image: -webkit-linear-gradient( 50deg, rgb(39,132,252) 0%, rgb(112,191,236) 100%);
            background-image: -ms-linear-gradient( 50deg, rgb(39,132,252) 0%, rgb(112,191,236) 100%);
        }
    }
    &.btn-rounded {
        border-radius: 30px;
    }
    &.blank {
        background-color: transparent;
        border: 2px solid $base-color;
        color: $base-color;
        &:hover {
            background-color: $base-color;
            color: $white;
        }
    }
    &.black {
        border: none;
        color: $white;
        background-color: $secondary-color;
        &:hover {
            background-color: $base-color;
            color: $white;
        }
    }
}
.boxed-btn-02{
    display: inline-block;
    text-align: center;
    height: 60px;
    line-height: 56px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
    color: $white;
    background-color: $base-color;
    padding: 0 30px;
    @include transition($transition);
    border-radius: 5px;
    border: 2px solid transparent;
    &:hover{
        background-color: $white;
        color: var(--main-color-one);
    }
    &.black{
        &:hover{
            background-color: var(--secondary-color);
            color: $white;
        }
    }
    &.reverse-color{
        background-color: $white;
        color: var(--main-color-one);
        &:hover{
            color: $white;
            background-color: var(--main-color-one);
        }
    }
    &.blank{
        background-color: transparent;
        color: $white;
        border: 2px solid $white;
        &:hover{
            color: var(--main-color-one);
            background-color: $white;
        }
    }
}
.section-title {
    text-align: center;
    &.white{
        .subtitle,.title{
            color: $white;
        }
        p{
            color: #e1dede;
        }
    }
    &.extra{
        margin-bottom: 55px;
        .title{
            margin-bottom: 26px;
        }
    }
    &.p-width-lg{
        p{
            max-width: 730px;
        }
    }
    &.left-aligned{
        text-align: left;
    }
    .title { 
        margin-bottom: 13px; 
        font-weight: 600;
        font-size: 40px;
        line-height: 1.2; 
        color: #454356;
        &.small {
            font-size: 36px;
            font-weight: 500;
        }  
        &.extra{
            margin-bottom: 24px;
        }
    }
    .subtitle{
        font-size: 20px;
        line-height: 30px;
        color: $base-color;
        text-transform: uppercase;
        font-weight: 600;
        font-family: $heading-font;
        display: block;
        margin-bottom: 10px;
    }
    p{
        font-size: 16px;
        color: #777777;
        max-width: 720px;
        line-height: 26px;
        margin: 0 auto;
        &.headline {
            font-size: 26px;
            line-height: 1.3;
            color: #454356;
            max-width: none;
            font-weight: 200;
        }
    }
    margin-bottom: 57px;
    .image {
        margin-top: 40px; 
        max-width: 220px;
    }
}
.margin-bottom-45{
    margin-bottom: 45px;
}
.c-white {
    color: $white;
}

.c-red {
    color: $base-color;
}

.bg-red {
    background-color: $base-color;
}

.c-green {
    color: #00cf92;
}
.padding-bottom-80{
    padding-bottom: 80px;
}
.padding-bottom-85{
    padding-bottom: 85px;
}
.padding-top-55{
    padding-top: 55px;
}
.gradient-bg{
    background-image: -moz-linear-gradient( 0deg, rgb(255,241,238) 0%, rgb(232,239,255) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(255,241,238) 0%, rgb(232,239,255) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(255,241,238) 0%, rgb(232,239,255) 100%);
}
.submit-btn {
    width: 180px;
    height: 60px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 60px;
    color: $white;
    text-transform: capitalize;
    background-color: $base-color;
    border: none;
    @include transition($transition);
    cursor: pointer;
    &:hover {
        background-color: $secondary-color;
    }
    &.btn-rounded {
        border-radius: 30px;
    }
    &.btn-center {
        display: block;
        margin: 0 auto;
        margin-top: 25px;
    }
    &:focus{
        outline: none;
    }
    &.gd-bg-1{
        background-image: -moz-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
        background-image: -webkit-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
        background-image: -ms-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
        &:hover{
            background-image: -moz-linear-gradient( 50deg, rgb(151,73,248) 0%,  rgb(94,44,237)100%);
            background-image: -webkit-linear-gradient( 50deg,rgb(151,73,248)  0%, rgb(94,44,237) 100%);
            background-image: -ms-linear-gradient( 50deg, rgb(151,73,248) 0%, rgb(94,44,237) 100%);
        }
    }
}

.section-title-inner {
    text-align: center;
    .subtitle {
        font-size: 18px;
        line-height: 28px;
        text-transform: uppercase;
        font-weight: 700;
        color: $base-color;
        margin-bottom: 20px;
        display: block;
    }
    .title {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;
    }
    margin-bottom: 45px
}

.form-element {
    &.margin-bottom-30 {
        margin-bottom: 27px;
    }
    label {
        color: $heading-color;
        font-size: 14px;
        font-weight: 700;
        line-height: 32px;
        span {
            color: #BE1F27;
        }
    }
    select {
        @include selectArrow(none);
    }
}

.has-icon {
    position: relative;
    display: block;
    &.textarea {
        .the-icon {
            top: 25px;
        }
    }
    .input-field {
        @include selectArrow(none);
    }
    .the-icon {
        font-size: 14px;
        position: absolute;
        right: 30px;
        top: 50%;
        @include translateY(-50%);
        color: #7c7c90;
    }
}
@media only screen and (max-width: 767px) {
    .row.reorder-xs {
        @include transform(rotate(180deg));
        direction: rtl;
    }
    .row.reorder-xs>[class*="col-"] {
        @include transform(rotate(-180deg));
        direction: ltr;
    }
}
@media only screen and (max-width: 991px) {
    .row.reorder-sm {
        @include transform(rotate(180deg));
        direction: rtl;
    }
    .row.reorder-sm>[class*="col-"] {
        @include transform(rotate(-180deg));
        direction: ltr;
    }
}
.input-field {
    width: 100%;
    height: 60px;
    padding: 0 30px;
    border-radius: 5px;
    color: #7c7c90;
    @include placeholder-color(#7c7c90);
    &.borderd {
        border: 2px solid rgba(0, 0, 0, 0.10);
        &:focus {
            border: 2px solid rgba(0, 0, 0, 0.10);
        }
    }
    &.textarea {
        min-height: 120px;
        padding: 20px 30px;
        resize: none;
    }
    &.error {
        border: 1px solid $base-color;
        @include placeholder-color($base-color);
        &:focus {
            border-color: $base-color;
        }
    }
}
.blog-details-content .single-blog-post .details-container .post-body .post-bottom-content .bottom-content .right-content .title {
    display: none;
}
.email-success {
    display: block;
    width: 100%;
}

.checkbox-element {
    display: inline-block;
}

.checkbox-wrapper {
    display: inline-block;
}

.checkbox-inner {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    color: #585869;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border: 2px solid rgba(0, 0, 0, 0.10);
    }
    input:checked~.checkmark {
        background-color: transparent;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked~.checkmark:after {
        display: block;
    }
    .checkmark:after {
        left: 5px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid $base-color;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate( 45deg);
    }
}
.table-responsive {
    display: table;
}
/*---------------------
    Breadcumb Area
----------------------*/

.breadcrumb-area {
    position: relative;
    z-index: 0;
    padding: 229px 0 140px 0;
    background-color: #1f2732;
    &.extra{
        padding-top: 232px;
    }
    .page-title {
        color: $white;
        font-size: 50px;
        line-height: 60px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .page-navigation{
        font-size: 18px;
        line-height: 20px;
        color: $base-color;
       li{
           display: inline-block;
           position: relative;
           margin: 0 10px;
           color: $white;
           &:first-child{
               margin-left: 0;
           }
           &:last-child{
               &:after{
                   display: none;
               }
           }
           &:after{
               position: absolute;
               right: -15px;
               top: 0px;
               font-family: 'Font Awesome 5 Free';
               content:'\f105';
               font-weight: 900;
           }
           a{
                color: $white;
                @include transition($transition);
            }
       }
    }
    &.breadcrumb-bg {
        background-image: url(../img/bg/breadcrumb-bg.jpg);
        background-size: cover;
        background-position: center;
    }
}

/*-------------------------
    Preloader Css
---------------------------*/
.preloader-wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999999;
    background-color: $white;
    .preloader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 60px;
        margin: -30px 0 0 -40px;
    }
}
    

.sk-circle {
    width: 80px;
    height: 80px;
    position: relative;
    display: block;
  }
  .sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $base-color;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
            animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  }
  .sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
            transform: rotate(30deg); }
  .sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
            transform: rotate(60deg); }
  .sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg); }
  .sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
            transform: rotate(120deg); }
  .sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
            transform: rotate(150deg); }
  .sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg); }
  .sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
            transform: rotate(210deg); }
  .sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
            transform: rotate(240deg); }
  .sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg); }
  .sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
            transform: rotate(300deg); }
  .sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
            transform: rotate(330deg); }
    .sk-circle .sk-circle1:before {
        background: #878BC9;
    }
  .sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s; 
            background: #8791C4;}
  .sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
            animation-delay: -1s; 
            background: #8897BF;}
  .sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s; 
            background: #879DBB;}
  .sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s; 
            background: #88A4B6;}
  .sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
            animation-delay: -0.7s; 
            background: #87ABB1;}
  .sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s; 
            background: #88B1AC;}
  .sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s; 
            background: #87BAA7;}
  .sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s; 
            background: #88BFA3;}
  .sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s; 
            background: #87C69F;}
  .sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s; 
            background: #87CC9A;}
  .sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
            animation-delay: -0.1s; 
            background: #87D496;}
  
  @-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    } 40% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    } 40% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  
  @keyframes slide {
    0% {
      clip: rect(0, 0, 20px, 0);
    }
    
    30% {
      clip: rect(0, 80px, 20px, 0);
    }
    
    50% {
      clip: rect(0, 80px, 20px, 0);
    }
    
    80% {
      clip: rect(0, 80px, 20px, 80px);
    }
    
    100% {
      clip: rect(0, 80px, 20px, 80px);
    }
  }
  
  @keyframes fade {
    0% {
      opacity: 1;
    }
    
    50% {
      opacity: 0;
    }
    
    100% {
      opacity: 1;
    }
}

.bg-horizontal {
    position: relative;
} 

@keyframes scrollBg {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: -100% 0;
    }
    100% {
        background-position: 0 0;
    }
}

.scroll-bg {
    height: 570px;
    width: 100%;
    background-image: url('../images/background.png');
    background-position: 0 0;
    background-size: auto 100%;
    animation: scrollBg 40s linear infinite;
}

.bg-map {
    background: url('../images/map.png') center right no-repeat;
    background-size: auto 500px;
}

.txt-green {
    color: #A2DC99 !important; 
}

.txt-purple { 
    color: #9390D3 !important;
}

.blocks {
    .blocks-title {
        font-family: "Poppins", sans-serif;
        display: block;
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 26px;
        line-height: 1.2;
        color: #454356;
    }
    .blocks-list {
        position: relative;
        margin: 0 50px 20px 0;
        padding: 12px;
        border-radius: 12px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-height: 120px;
        background: white;
        span {
            display: block;
        }
        .label {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: -46px;
            text-transform: uppercase;
            font-weight: 600;
            color: #fff;
            background: #464358; 
            border-radius: 12px;
            height: 115px;
            width: 45px; 
            padding: 5px 10px; 
            span {
                font-size: 16px;
                display: block;
                transform: rotate(270deg);
            }
        }
        .image {
            width: 170px;
            margin-right: 20px;
            &.small {
                width: 120px;
            }
            img {
                width: 95px;
            }
        }
        h5 {
            color: #9390D3;
            font-size: 15px;
            margin: 5px 0 5px 0;
        }
        p {
            color: #464358;
            font-size: 13px;
            margin: 0; 
        }
    }
    .mobile {
        margin-top: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .image {
            width: 70px;
            margin-right: 15px;
        }
        span {
            display: block;
            font-size: 20px;
            font-weight: 100;
            color: #464358; 
        }
    }
}

.bg-linear {
    background: linear-gradient(90deg, #9491D5, #A2DD9A);
}

.section-solution {
    padding-top: 200px;
    padding-bottom: 300px;
}

.main-features {
    text-align: center;
    img {
        display: block;
        margin: 20px auto;
    }
    .title {
        text-transform: uppercase;
        color: #9390D3;
        font-weight: 700;
    }
    .lists {
        text-align: center;
        li {
            display: inline-block;
            border: 1px solid #9ADA9C;
            padding: 8px;
            @extend .bg-linear;
            border-radius: 30px;
            font-size: 20px;
            font-weight: 600;
            span {
                display: block;
                padding: 8px 20px;
                color: #595764;
            }
        }
    }
}

.copyright {
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 2px solid rgba(#9390D3, 0.2);
    margin-top: 40px;
    p, a {
        margin: 0;
        padding: 0;  
        color: #595764;
        font-size: 14px;
    } 
    a:hover {
        color: black;
    }
}

.policy {
    .title {
        margin-top: 0px!important;
        margin-bottom: 40px!important; 
    }
    p {
        max-width: 700px !important;
    }
}

.video-showcase {
    display: block;
    width: 800px;
    height: 450px;
    margin: 0 auto;
    max-width: 100%;
    // border: 2px solid;
}