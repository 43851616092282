/*------------------------
    Featured Area
------------------------*/
.about-us-area{
    padding: 113px 0 120px 0;
    position: relative;
    .shape-1{
        position: absolute;
        right: 10%;
        bottom: 10%;
        animation: upndown 10s linear 0s infinite;
    }
    .shape-2{
        position: absolute;
        left: 10%;
        top: 15%;
        animation: roatate 10s linear 5s infinite;
        display: inline-block;
    }
    .btn-wrapper {
        text-align: center;
        margin-top: 60px;
        .boxed-btn{
            margin: 0 10px;
        }
    }
    &.style-two{
        padding-top: 118px;
        padding-bottom: 80px;
        .section-title{
            padding-right: 100px;
        }
        .feature-list{
            flex-wrap: wrap;
            border: none;
            .single-feature-list{
                margin-bottom: 20px;
                width: 50%;
                border: none;
                padding: 0 20px;
                text-align: left;
                .icon{
                    text-align: center;
                }
            }
        }
    }
    
}

.feature-list{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    border: 2px solid #ededed;
    &.white{
        border-color: rgba($white,.2);
    }
    &.style-03{
        .single-feature-list{
            background-color: $white;
            position: relative;
            z-index: 0;
            overflow: hidden;
            .icon{
                position: relative;
                z-index: 0;
                font-size: 36px;
                &:after{
                    position: absolute;
                    left: 15px;
                    top: 0;
                    width: 70px;
                    height:90px;
                    border-radius: 40px;
                    content:'';
                    @include transform(rotate(40deg));
                    z-index: -1;
                }
                &.icon-bg-1{
                    background-image: unset;
                    &:after{
                        background-image: -moz-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
                        background-image: -webkit-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
                        background-image: -ms-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
                    }
                }
                &.icon-bg-2{
                    background-image: unset;
                    &:after{
                        background-image: -moz-linear-gradient( 50deg, rgb(232,119,203) 0%, rgb(161,151,250) 100%);
                        background-image: -webkit-linear-gradient( 50deg, rgb(232,119,203) 0%, rgb(161,151,250) 100%);
                        background-image: -ms-linear-gradient( 50deg, rgb(232,119,203) 0%, rgb(161,151,250) 100%);
                    }
                }
                &.icon-bg-3{
                    background-image: unset;
                    &:after{
                        background-image: -moz-linear-gradient( 50deg, rgb(112,191,236) 0%, rgb(39,132,252) 100%);
                        background-image: -webkit-linear-gradient( 50deg, rgb(112,191,236) 0%, rgb(39,132,252) 100%);
                        background-image: -ms-linear-gradient( 50deg, rgb(112,191,236) 0%, rgb(39,132,252) 100%);
                    }
                }
                &.icon-bg-4{
                    background-image: unset;
                    &:after{
                        background-image: -moz-linear-gradient( 90deg, rgb(236,108,127) 0%, rgb(250,159,105) 100%);
                        background-image: -webkit-linear-gradient( 90deg, rgb(236,108,127) 0%, rgb(250,159,105) 100%);
                        background-image: -ms-linear-gradient( 90deg, rgb(236,108,127) 0%, rgb(250,159,105) 100%);
                    }
                }
            }
            &:after{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 5px;
                background-image: -moz-linear-gradient( -177deg, rgb(2,80,197) 0%, rgb(212,63,141) 100%);
                background-image: -webkit-linear-gradient( -177deg, rgb(2,80,197) 0%, rgb(212,63,141) 100%);
                background-image: -ms-linear-gradient( -177deg, rgb(2,80,197) 0%, rgb(212,63,141) 100%);
                content: '';
                @include transform(translateX(-105%));
                transition: transform 1s;
            }
            &:hover{
                box-shadow: 0px 0px 95px 0px rgba(0, 0, 0, 0.07);
                &:after{
                    @include transform(translateX(0%));
                }
            }
        }
    }
}
.single-feature-list{
    &.white{
        border-right: 2px solid rgba($white,.2);
        .content{
            .title{
                a{
                    color: $white;
                }
            }
            p{
                color: rgba($white,.7);
            }
        }
    }
    display: block;
    text-align: center;
    padding: 60px 20px 40px 20px;
    border-right: 2px solid #ededed;
    &:hover{
        .icon{
            @include transform(rotateY(360deg));
        }
    }
    .icon{
        font-size: 40px;
        display: inline-block;
        margin: 0 auto;
        width: 100px;
        height: 100px;
        line-height: 100px;
        color: $white;
        margin-bottom: 15px;
        @include transition($transition);
        &.icon-bg-1{
            background-image: url(../img/icon-bg/01.png);
            background-repeat: no-repeat;
        }
        &.icon-bg-2{
            background-image: url(../img/icon-bg/02.png);
            background-repeat: no-repeat;
        }
        &.icon-bg-3{
            background-image: url(../img/icon-bg/03.png);
            background-repeat: no-repeat;
        }
        &.icon-bg-4{
            background-image: url(../img/icon-bg/04.png);
            background-repeat: no-repeat;
        }
    }
    .content{
        .title{
            font-size: 22px;
            line-height: 32px;
            font-weight: 600;
            margin-bottom: 15px;
            a{
                color: $heading-color;
                @include transition($transition);
                &:hover{
                    color: $base-color;
                }
            }
        }
        p{}
    }
}

.feature-list-04{
    .single-feature-list-item-04{
        +.single-feature-list-item-04{
            margin-top:30px;
        }
        padding: 30px 30px;
        box-shadow: 0px 0px 95px 0px rgba(0, 0, 0, 0.07);
        display: flex;
        align-self: flex-start;
        transition: all 500ms;
        &:hover{
            box-shadow: 0px 0px 155px 0px rgba(0, 0, 0, 0.2);
        }
        &.theme-01{
            .icon{
                color: #e81273;
            }
        }
        &.theme-02{
            .icon{
                color: #3ceb9e;
            }
        }
        &.theme-03{
            .icon{
                color: #f99c6a;
            }
        }
        .icon{
            font-size:40px;
            line-height: 50px;
            margin-right: 20px;
            transition: all 500ms;
        }
        .content{
            flex: 1;
            .title{}
            p{
                margin-bottom: 0px;
            }
        }
    }
}
.single-feature-item-02{
        background-color: $white;
        position: relative;
        z-index: 0;
        overflow: hidden;
        padding: 25px;
        text-align: center;
        transition: 500ms all;
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,.10);
        border-radius: 30px;
          
        .icon{
            position: relative;
            // z-index: 0;
            // font-size: 36px;
            // line-height: 46px;
            // color: $white;
            margin-bottom: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                margin: 0 auto;
                max-height: 60px;
            }
            // &:after{
            //     position: absolute;
            //     left: 50%;
            //     top: 5px;
            //     width: 70px;
            //     height:90px;
            //     border-radius: 40px;
            //     content:'';
            //     @include transform(rotate(40deg)translateX(-60%));
            //     z-index: -1;
            // }
            // &.icon-bg-1{
            //     background-image: unset;
            //     &:after{
            //         background-image: -moz-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
            //         background-image: -webkit-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
            //         background-image: -ms-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
            //     }
            // }
            // &.icon-bg-2{
            //     background-image: unset;
            //     &:after{
            //         background-image: -moz-linear-gradient( 50deg, rgb(232,119,203) 0%, rgb(161,151,250) 100%);
            //         background-image: -webkit-linear-gradient( 50deg, rgb(232,119,203) 0%, rgb(161,151,250) 100%);
            //         background-image: -ms-linear-gradient( 50deg, rgb(232,119,203) 0%, rgb(161,151,250) 100%);
            //     }
            // }
            // &.icon-bg-3{
            //     background-image: unset;
            //     &:after{
            //         background-image: -moz-linear-gradient( 50deg, rgb(112,191,236) 0%, rgb(39,132,252) 100%);
            //         background-image: -webkit-linear-gradient( 50deg, rgb(112,191,236) 0%, rgb(39,132,252) 100%);
            //         background-image: -ms-linear-gradient( 50deg, rgb(112,191,236) 0%, rgb(39,132,252) 100%);
            //     }
            // }
            // &.icon-bg-4{
            //     background-image: unset;
            //     &:after{
            //         background-image: -moz-linear-gradient( 90deg, rgb(236,108,127) 0%, rgb(250,159,105) 100%);
            //         background-image: -webkit-linear-gradient( 90deg, rgb(236,108,127) 0%, rgb(250,159,105) 100%);
            //         background-image: -ms-linear-gradient( 90deg, rgb(236,108,127) 0%, rgb(250,159,105) 100%);
            //     }
            // }
        }
        &:hover{
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.07);
        }
        .content{
            .title{
                font-size: 20px;
                font-weight: 300;
                line-height: 1.2;
                color: #929292;
                margin: 0;
                padding: 0;
            }
        }
}