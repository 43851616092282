/*------------------------------
    Header Area
------------------------------*/
 
.header-area {
    padding: 352px 0 150px 0; 
    background-color: $white;
    position: relative;
    .shape-1{
        position: absolute;
        left: 10%;
        top: 15%;
        animation: left2right 10s linear 0s infinite;
        opacity: .1;
    }
    .shape-2{
        position: absolute;
        right: 5%;
        top: 10%;
        animation: upndown 10s linear 0s infinite;
        opacity: .3;
    }
    .shape-3{
        position: absolute;
        left: 50%;
        bottom: 30%;
        animation: left2right 10s linear 0s infinite;
        opacity: .2;
    }
    .header-right-image{
        position: absolute;
        bottom: 0px;
        right: 10%;
    }
   
    &.header-bg{
        background-color: $white;
        background-image: url(../img/bg/header-bg.png);
        background-position: top right;
        background-repeat: no-repeat;
    }
    &.header-bg-2{
        background-color: $white;
        background-image: url(../img/bg/header-bg-3.png);
        background-position: top right;
        background-repeat: no-repeat;
    }
    &.header-bg-3{
        background-color: $white;
        background-image: url(../img/bg/header-bg-4.jpg);
        background-position: center;
        background-size: cover;
    }
    &.header-bg-4{
        background-color: $white;
        background-image: url(../img/bg/header-bg-3.png);
        background-position: center;
        background-size: cover;
    }
    &.header-bg-5{
        background-color: $white;
        background-image: url(../img/bg/header-bg-3.png);
        background-position: center;
        background-size: cover;
        &.dark-bg{
            background-color: #10102D;
        }
    }
    &.header-bg-6{
        background-color: $white;
        background-image: url(../img/bg/header-bg-4.jpg);
        background-position: center;
        background-size: cover;
    }
    &.header-bg-12{
        background-color: $white;
        // background-image: url(../img/bg/header-bottom-bg-12.png);
        background-position: bottom;
        background-size: contain;
        background-repeat: no-repeat;
    }
    &.header-bg-9{
        background-color: $white;
        background-image: url(../img/bg/header-bg-9.jpg);
        background-position: center;
        background-size: cover;
        position: relative;
        z-index: 0;
        &:after{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: '';
            z-index: -1;
            background-image: -moz-linear-gradient( 146deg, rgb(2,80,197) 0%, rgb(202,60,135) 60%, rgb(212,63,141) 100%);
            background-image: -webkit-linear-gradient( 146deg, rgb(2,80,197) 0%, rgb(202,60,135) 60%, rgb(212,63,141) 100%);
            background-image: -ms-linear-gradient( 146deg, rgb(2,80,197) 0%, rgb(202,60,135) 60%, rgb(212,63,141) 100%);
            opacity: .9;
        }
    }
    &.header-bg-10{
        background-color: $white;
        background-image: url(../img/bg/header-bg-10.jpg);
        background-position: center;
        background-size: cover;
        position: relative;
        z-index: 0;
        &:after{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: '';
            background-color: rgba(#1a1f24,.7);
            z-index: -1;
            opacity: .9;
            background-image: url(../img/bg/header-bg-overlay-10.png);
            background-position: bottom;
            background-repeat: no-repeat;
        }
    }
    &.header-bg-11{
        background-color: $white;
        background-image: url(../img/bg/header-bg-11.jpg);
        background-position: center;
        background-size: cover;
        position: relative;
        z-index: 0;
        &:after{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: '';
            background-color: rgba(#1a1f24,.7);
            z-index: -1;
            background-image: url(../img/bg-shape-11.png);
            background-position: bottom;
            background-repeat: no-repeat;
        }
    }
    &.dark-home-1{
        background-color: $white;
        background-image: url(../img/bg/dark-bg-1.png);
        background-position: bottom;
        background-size: cover;
        background-color: #10102d;
        .shape-1{
            position: absolute;
            left: 10%;
            top: 15%;
            animation: upndown 10s linear 0s infinite;
        }
        .shape-2{
            position: absolute;
            right: 5%;
            top: 10%;
            animation: upndown 10s linear 0s infinite;
        }
        .shape-3{
            position: absolute;
            left: 50%;
            bottom: 30%;
            animation: left2right 10s linear 0s infinite;
        }
        .shape-4{
            position: absolute;
            left: 55%;
            top: 15%;
            animation: roatate 10s linear 5s infinite;
            display: inline-block;
        }
        .header-inner{
            .title{
                color: $white;
            }
            p{
                color: rgba($white,.8);
            }
        }
    }
    &.dark-home-2{
        background-color: $white;
        background-image: url(../img/bg/dark-bg-2.png);
        background-position: bottom;
        background-size: cover;
        background-color: #10102d;
        .shape-1{
            position: absolute;
            left: 10%;
            top: 15%;
            animation: upndown 10s linear 0s infinite;
        }
        .shape-2{
            position: absolute;
            right: 5%;
            top: 10%;
            animation: upndown 10s linear 0s infinite;
        }
        .shape-3{
            position: absolute;
            left: 50%;
            bottom: 30%;
            animation: left2right 10s linear 0s infinite;
        }
        .shape-4{
            position: absolute;
            left: 55%;
            top: 15%;
            animation: roatate 10s linear 5s infinite;
            display: inline-block;
        }
        .header-inner{
            .title{
                color: $white;
            }
            p{
                color: rgba($white,.8);
            }
        }
    }
    &.style-six{
        .header-inner{
            text-align: center;
            .video-play-btn{
                color: $base-color;
                margin-bottom: 30px;
            }
            .title{
                color: $white;
            }
            p{
                color: rgba($white,.8);
                margin: 0 auto;
            }
        }
    }
    &.style-five{
        .header-inner{
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            .title{
                color: $white;
            }
            p{
                color: rgba($white,.8);
            }
        }
    }
    &.style-four{
        .header-inner{
            .title{
                color: $white;
            }
            p{
                color: rgba($white,.8);
            }
            .free-trail-form{
                position: relative;
                z-index: 0;
                max-width: 500px;
                margin-top: 36px;
                .form-group{
                    .form-control{
                        height: 60px;
                        padding: 0 170px 0 20px;
                    }
                }
                .submit-btn{
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: $right;
                    @include transition($transition);
                    cursor: pointer;
                    width: 150px;
                    &:hover{
                        background-color: $left;
                    }
                }
            }
        }
    }
    &.style-three{
        .header-overlay-image{
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            @include transform(scale(1.05));
            .st1,.st0{
                fill: $white;
            }
        }
        .header-inner{
            .title{
                color: $white;
            }
            p{
                color: rgba($white,.8);
            }
        }
    }
    &.style-09{
        padding-top: 235px;
        padding-bottom: 160px;
        .header-inner{
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
        }
        .header-overlay-image{
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            @include transform(scale(1.05));
            .st1,.st0{
                fill: $white;
            }
        }
        .header-inner{
            .title{
                color: $white; 
            }
            p{
                color: rgba($white,.8);
                font-size: 18px;
                line-height: 28px;
            }
            .btn-wrapper{
                margin-top: 20px;
                .boxed-btn-02{
                    +.boxed-btn-02{
                        margin-left: 15px;
                    }
                }
            }
        }
    }
    &.style-10{
        padding-top: 235px;
        padding-bottom: 160px;
        .header-inner{
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
        }
        .header-overlay-image{
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            @include transform(scale(1.05));
            .st1,.st0{
                fill: $white;
            }
        }
        .header-inner{
            .title{
                color: $white; 
            }
            p{
                color: rgba($white,.8);
                font-size: 18px;
                line-height: 28px;
            }
            .btn-wrapper{
                margin-top: 20px;
                .boxed-btn-02{
                    +.boxed-btn-02{
                        margin-left: 15px;
                    }
                }
            }
        }
    }
    &.style-11{
        padding-top: 235px;
        padding-bottom: 160px;
        .header-inner{
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
        }
        .header-overlay-image{
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            @include transform(scale(1.05));
            .st1,.st0{
                fill: $white;
            }
        }
        .header-inner{
            .title{
                color: $white;
                font-size: 58px;
                line-height: 68px;
            }
            p{
                color: rgba($white,.8);
                font-size: 18px;
                line-height: 28px;
            }
            .btn-wrapper{
                margin-top: 20px;
                .boxed-btn-02{
                    +.boxed-btn-02{
                        margin-left: 15px;
                    }
                }
            }
        }
    }
    &.style-12{
        padding-top: 90px;
        padding-bottom: 50px;
        .header-inner{
            text-align: center;
            .title{
                margin-top: 50px;
                margin-bottom: 0; 
            }
            .btn-wrapper{
                margin-top: 40px;
                .boxed-btn-02{
                    background-color: var(--main-color-one);
                    color: #fff;
                    border: 2px solid var(--main-color-one);
                    &:hover{
                        background-color: transparent;
                        color: var(--main-color-one);
                        border-color: var(--main-color-one);
                    }
                    +.boxed-btn-02{
                        background-color: transparent;
                        color: var(--main-color-one);
                        border-color: var(--main-color-one);
                        margin-left: 15px;
                        &:hover{
                            background-color: var(--main-color-one);
                            color: #fff;
                            border: 2px solid var(--main-color-one);
                        }
                    }
                }
            }
        }
    }
    &.style-two{
        .header-inner{
            .title{
                color: $white;
            }
            p{
                color: rgba($white,.8);
            }
        }
    }
    
    .header-inner {
        h1 {
            margin: 0 auto 25px !important;
            text-align: center;
            display: inline-block;
            img {
                height: 60px;
                display: block; 
            }
        }
        .title {
            // font-size: 60px; 
            color: #454356;
            margin-bottom: 22px; 
            font-weight: 600;
            font-size: 50px;
            line-height: 1.2;  
        }
        p{
            font-size: 20px;
            line-height: 32px;
            color: #505b6d;
            max-width: 520px; 
        }
        
    }
}


.header-form-area{
    .header-form-inner{
        background-color: $white;
        padding: 40px 30px 50px 40px;
        border-radius: 5px;
        border: 1px solid #e2e2e2;
        .title{
            font-size: 30px;
            line-height: 40px;
            color: $heading-color;
            margin-bottom: 25px;
        }
        
    }
}
.get-quote-form{
    .form-group{
        &.textarea{
            .form-control{
                min-height: 140px;
                resize: none;
                &:focus{
                    box-shadow: none;
                }
            }
        }
        .form-control{
            height: 50px;
            border: 1px solid #e3e3e3;
        }
    }
    .submit-btn{
        font-size: 16px;
        font-weight: 600;
        border-radius: 3px;
        @include gradient-left-right($left,$right);
        &:hover{
            box-shadow: 0px 3px 20px 0px rgba(13, 21, 75, 0.3);
        }
    }
}

@include keyframes(upndown){
    0%{
        @include transform(translateY(0px))
    }
    50%{
        @include transform(translateY(100px))
    }
    100%{@include transform(translateY(0px))}
}
@include keyframes(left2right){
    0%{
        @include transform(translateX(0px))
    }
    50%{
        @include transform(translateX(100px))
    }
    100%{@include transform(translateX(0px))}
}

@include keyframes(roatate){
    0%{
        @include transform(rotate(90deg));
        opacity: 0.3;
    }
    25%{
        opacity: 0.6;
        @include transform(rotate(180deg));
    }
    50%{
        @include transform(rotate(-90deg));
        opacity: 0.8;
    }
   75%{
        @include transform(rotate(-180deg));
        opacity: 0.6;
    }
    100%{
        @include transform(rotate(-90deg));
        opacity: 0.3;
    }
}


.btn-wrapper {
    margin-top: 35px;
    .boxed-btn {
        margin: 0 10px;
        border: none;
        width: 165px;
        height: 60px;
        line-height: 55px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        font-size: 16px;
        font-weight: 600;
        border: 2px solid $white;
        background-image: -moz-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
        background-image: -webkit-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
        background-image: -ms-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
        box-shadow: 0px 3px 20px 0px rgba(13, 21, 75, 0.3);
        @include transition($transition);
        text-transform: capitalize;
        &.blank{
            background-color: $white;
            color: #454356;
            border: 2px solid $base-color;
            @include gradient-left-right(transparent,transparent);
            @include transition($transition);
            img{
                margin-right: 3px;
            }
            &:hover{
                @include gradient-left-right($left,$right);
                border-color: $white;
                color: $white;
            }
        }
        &:hover{
            @include gradient-left-right(transparent,transparent);
            background-color: $white;
            color: #505b6d;
        }
    }
}

.bg-section {
    background: url('../images/bg-sections.png') center -150px no-repeat;
}