/*-------------------------
    How It Works Area
---------------------------*/
.how-it-work-area{
    padding: 112px 0 112px 0;
    position: relative;
    .shape-1{
        position: absolute;
        left: 10%;
        top: 10%;
        animation: upndown 10s linear 2s infinite;
    }
    .shape-2{
        position: absolute;
        left: 6%;
        top: 20%;
        animation: upndown 8s linear 2s infinite;
        opacity: .5;
    }
    .shape-3{
        position: absolute;
        right: 10%;
        bottom: 10%;
        animation: upndown 10s linear 2s infinite;
    }
    .shape-4{
        position: absolute;
        right: 6%;
        bottom: 20%;
        animation: upndown 8s linear 2s infinite;
        opacity: .5;
    }
}

.how-it-work-tab-nav{
    margin-bottom: 40px;
    .nav-tabs{
        border: none;
        display: block;
        text-align: center;
        font-size: 0;
        margin-top: 18px;
        .nav-item{
            display: inline-block;
            border-right: 1px solid #b1aeb6;
            &:last-child{
                border-right: none;
            }
            .nav-link{
                border: none;
                height: 70px;
                line-height: 60px;
                padding: 0 30px;
                color: $heading-color;
                background-color: #f6f2fd;
                font-size: 16px;
                font-weight: 500;
                position: relative;
                .number{
                    position: absolute;
                    top: -20px;
                    right: 30px;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-image: -moz-linear-gradient( 50deg, rgb(232,119,203) 0%, rgb(161,151,250) 100%);
                    background-image: -webkit-linear-gradient( 50deg, rgb(232,119,203) 0%, rgb(161,151,250) 100%);
                    background-image: -ms-linear-gradient( 50deg, rgb(232,119,203) 0%, rgb(161,151,250) 100%);
                    text-align: center;
                    line-height: 40px;
                    font-weight: 600;
                    color: $white;
                    border: 2px solid $white;
                }
                i{
                    font-size: 30px;
                    position: relative;
                    top: 5px;
                    color: $base-color;
                }
                &.active{
                   i{
                       color: $white;
                   }
                    color: $white;
                    background-image: -moz-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
                    background-image: -webkit-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
                    background-image: -ms-linear-gradient( 50deg, rgb(94,44,237) 0%, rgb(151,73,248) 100%);
                }
            }
        }
    }
}
.how-it-works-tab-content{
    &.white{
        .left-content-area{
            .title{
                color: $white;
            }
            p{color: rgba($white,.7);}
        }
    }
    .left-content-area{
        .title{
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 15px;
            color: $heading-color;
        }
        p{}
    }
    .right-content-area{
        .imw-wrapper{}
    }
}