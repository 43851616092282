/*-------------------------
    Contact Area
---------------------------*/
.contact-form-area-02{
    &.contact-bg{
        background-image: url(../images/bg-footer.png);
        background-position: left top;
        background-repeat: no-repeat;
    }
    .img-wrapper{
        text-align: right;
        padding-left: 40px;
    }
    .contact-area-wrapper{
        padding: 0;
    }
}
