/*--------------------
    Call TO Action 
--------------------*/
.call-to-action-inner-style-02{
    
    padding: 90px 0 100px 0;
    position: relative;
    z-index: 0;
    background-image: url('../img/bg/header-bg-9.jpg');
    text-align: center;
    border-radius: 10px;
    margin-bottom: -200px;
    &:after{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: -1;
        background-image: -moz-linear-gradient( 176deg, rgb(2,80,197) 0%, rgb(212,63,141) 100%);
        background-image: -webkit-linear-gradient( 176deg, rgb(2,80,197) 0%, rgb(212,63,141) 100%);
        background-image: -ms-linear-gradient( 176deg, rgb(2,80,197) 0%, rgb(212,63,141) 100%);
        opacity: .8;
        border-radius: 10px;
    }
    .title{
        font-size: 48px;
        line-height: 60px;
        color: $white;
    }
    .btn-wrapper{
        .boxed-btn-02{
            +.boxed-btn-02{
                margin-left: 20px;
            }
        }
    }
}