/*------------------------
    Video Area
-------------------------*/
.about-app-area{
    background-repeat: no-repeat;
    .img-wrapper{
        position: relative;
        z-index: 2;
    }
}

.img-full-width-video{
    position: relative;
    z-index: 0;
    img{
        border-radius: 10px;
    }
    .hover{
        position: absolute;
        right: 15%;
        top: 50%;
        @include transform(translateY(-50%));
        .play-video-btn{
            display: inline-block;
            padding: 15px 40px;
            border-radius: 5px;
            background-image: -moz-linear-gradient( 176deg, rgb(2,80,197) 0%, rgb(212,63,141) 100%);
            background-image: -webkit-linear-gradient( 176deg, rgb(2,80,197) 0%, rgb(212,63,141) 100%);
            background-image: -ms-linear-gradient( 176deg, rgb(2,80,197) 0%, rgb(212,63,141) 100%);
            color: $white;
            font-weight: 600;
        }
    }
}
.img-with-video{
    display: inline-block;
    .img-wrap{
        position: relative;
        z-index: 0;
        .hover{
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(#622eee,.6);
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .video-play-btn{
                color: $base-color;
                width: 100px;
                height: 100px;
                line-height: 100px;
                &:before{
                    width: 120px;
                    height: 120px;
                }
            }
        }
    }
}

.video-area{
    &.white{
        .right-content-area{
            .title {
                color: $white;
            }
            .subtitle{
                color: rgba($white,.8);
            }
            p{
                color: rgba($white,.6);
            }
        }
    }
    &.style-two{
        position: relative;
        z-index: 0;
        &:after{
            position: absolute;
            left: 0;
            top: 0;
            background-image: url(../img/bg/video-area-bg.png);
            content: '';
            z-index: -1;
            width: 100%;
            height: 100%;
        }
    }
    .right-content-area{
        margin-top: 45px;
        .title {
            font-size: 48px;
            line-height: 58px;
            margin-bottom: 20px;
            font-weight: 700;
            color: $heading-color;
        }
        .subtitle{
            font-size: 20px;
            line-height: 30px;
            color: $base-color;
            text-transform: uppercase;
            font-weight: 600;
            font-family: $heading-font;
            display: block;
            margin-bottom: 10px;
        }
    }
}
.has-video-with-img{
    padding-top: 370px;
}
.video-play-area {
    &.margin-minus{
        margin-bottom: -250px;
    }
}
.video-play-width-image{
    position: relative;
    z-index: 0;
   z-index: 2;
    img{
        border-radius: 5px;
    }
    .hover{
        position: absolute;
        left: 50%;
        top: 50%;
        @include transform(translate(-50%,-50%));
        .video-play-btn{
            background-color: var(--main-color-one);
            &:before{
                background-color: var(--main-color-one);
            }
            color: $white;
        }
    }
}