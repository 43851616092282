/*----------------------------------
    Customer Reply Area
----------------------------------*/
.customer-reply-area{
    position: relative;
    z-index: 0;
    .right-image{
        position: absolute;
        right: 0;
        top: 0;
    }
}
.single-amazing-feature-01{
    margin-right: 40px;
    display: flex;
    align-self: flex-start;
    &:hover{
        &.theme-01{
            .icon{
                background-color: #e81273;
                color:#fff;
            }
        }
        &.theme-02{
            .icon{
                background-color: #3993f8;
                color: #fff;
            }
        }
        &.theme-03{
           .icon{
            background-color: #3ceb9e;
            color: #fff;
           }
        }
        &.theme-04{
            .icon{
                background-color: #f99c6a;
                color: #fff;
            }
        }
        .content{}
    }
    &.theme-01{
        .icon{
            color:#e81273;
        }
    }
    &.theme-02{
        .icon{
            color: #3993f8;
        }
    }
    &.theme-03{
       .icon{
        color: #3ceb9e;
       }
    }
    &.theme-04{
        .icon{
            color: #f99c6a;
        }
    }
    .icon{
        font-size: 40px;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 1px solid #e2e2e2;
        border-radius: 50%;
        margin-right: 20px;
        transition: all 500ms;
    }
    .content{
        flex: 1;
        .title{
            font-size: 24px;
            line-height: 34px;
            font-weight: 700;
        }
        p{}
    }
}