/*------------------------
    Icon Box Items
-------------------------*/
.single-icon-box-03{
    display: flex;
    align-self: flex-start;
    &.theme-01{
        .icon{
            color: #e81273;
        }
        &:hover{
            .icon{
                background-color: #e81273;
                color: $white;
            }
        }
    }
    &.theme-02{
        .icon{
            color: #3993f8;
        }
        &:hover{
            .icon{
                background-color: #3993f8;
                color: $white;
            }
        }
    }
    &.theme-03{
        .icon{
            color: #3ceb9e;
        }
        &:hover{
            .icon{
                background-color: #3ceb9e;
                color: $white;
            }
        }
    }
    &.theme-04{
        .icon{
            color: #f99c6a;
        }
        &:hover{
            .icon{
                background-color: #f99c6a;
                color: $white;
            }
        }
    }
   
    .icon{
        margin-right: 20px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 1px solid #cfcfcf ;
        line-height: 100px;
        text-align: center;
        font-size: 36px;
        color: var(--main-color-one);
        transition: all 500ms;
    }
    .content{
        flex: 1;
        .title{}
        p{}
    }
}